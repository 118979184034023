import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getCostCentersPurshase({ commit }, companyCode) {
      try {
        const response = await api.get(
          `/flows/${companyCode}/data/purchasings/financial-accounts`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getNaturesPurshasing({ commit }, companyCode) {
      try {
        const response = await api.get(
          `/flows/${companyCode}/data/purchasings/natures`,
        )
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getPurchasingBudget({ commit }, companyCode) {
      try {
        const response = await api.get(`/flows/${companyCode}/data/purchasings`)
        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async deleteComprasForm({ commit }, data) {
      try {
        const response = await api.delete(
          `/flows/${data.companyCode}/data/purchasings/${data.id}`,
        )
        return response
      } catch (error) {
        throw error
      }
    },
    async updateFormPurchasing({ commit }, data) {
      try {
        const response = await api.put(
          `/flows/${data.companyCode}/data/purchasings/${data.id}`,
          data.form,
        )
        return response
      } catch (error) {
        throw error
      }
    },
    async saveRegistersPurchasing({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/purchasings`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async finishPurchasingForm({ commit }, data) {
      try {
        const response = await api.put(
          `/flows/${data.companyCode}/data/purchasings/finish`,
          data.form,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
