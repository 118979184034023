import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getLastedControllershipEvaluation({ commit }, data) {
      try {
        const response = await api.get(
          `/flows/${data.companyCode}/data/controllership-evaluation?stepId=${data.stepId}&formId=${data.formId}`,
        )
        return response && response.status ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async avaliateControllershipEvaluationCommercial({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/controllership-evaluation/commercial`,
          data.data,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async avaliateControllershipEvaluationPurchasing({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/controllership-evaluation/purchasing`,
          data.data,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async avaliateControllershipEvaluationLaborCost({ commit }, data) {
      try {
        const response = await api.post(
          `/flows/${data.companyCode}/data/controllership-evaluation/labor-costs`,
          data.data,
        )
        return response && response.status ? response : null
      } catch (error) {
        throw error
      }
    },
    async getAvaliationControllershipEvaluation({ commit }, data) {
      try {
        const response = await api.get(
          `/flows/${data.companyCode}/data/controllership-evaluation/avaliation?version=${data.version}&type=${data.type}`,
        )
        return response && response.status ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
